$primary-color: #3a4a66;
$white-color: white;
$gray-color: gray;
$font-color: #3a4a66;
$sky-blue: #68d6f0;
$red-color: #ef6565;
$green-color: #97cc2e;
$yellow-color: #ffcb3d;
$lightRed-color: #ffcbcb;
$lightGreen-color: #c7ea71;
$bootstrap-input-text-color: #495057;
$light-background-color: #dcdcdc;
$light-sky-blue: #ebf1fb;
$violet-color: #7676ff;
$violet-blue-color: #76b1ff;

$white: #ffffff;
$black: #000000;
$red: #ef6565;
$redLight: #ffcbcb;
$petrol: #64928d;
$brown: #c29e61;
$grey: #626e7f;
$green: #97cc2e;
$greenLight: #c7ea71;
$gold: #ffd267;
$blue: #68d6f0;
$blueLight: #6c99f4;
$orange: #ffcb3d;
$googleCal: #fbbc05;
$iCloudCal: #6d6e71;
$exchangeCal: #0072c6;
$office365Cal: #dc3701;

$lightGrey: #fafafa;
$mediumGrey: #e7e7e7;
$darkGrey: #777;
$defaultGrey: #bebebe;
$tableGreyTitle: #bfbfbf;
$tableGreyRow: #e4e4e4;
$adminBlue: #3a4a66;
$adminBlueLight: #243146;
$adminBlueDark: #4a5a76;
$calensoBabyBlue: #68d6f0;
$calensoBabyBlueLight: #def9ff;
$calensoWarningLight: #ffd267;

$extCalGrey: #3A4A66;
$extCalGreyBg: #a9b9d4;
$eventBlocker: #76B1FF;
$eventBlockerBg: #AACDFC;
$defaultAppointment: #7676FF;
$defaultAppointmentBg: #B1B2FD;
$calOrange: #fea013;
$calOrangeBg: #ffeed6;
$calYellow: #FFCB3D;
$calYellowBg: #ffee9a;
$calRed: #db2f1b;
$calRedBg: #fad9d5;
$calGreen: #23b632;
$calGreenBg: #d2f6d5;

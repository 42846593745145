@use "@angular/material" as mat;

@import "./app/styles/sass/saas-variables.scss";
@import "./assets/material-overwrite/mat-date-picker.scss";

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 100;
  src: url(assets/fonts/Lato/Lato-normal-100.woff) format("woff"),
    url(assets/fonts/Lato/Lato-normal-100.ttf) format("truetype"),
    url(assets/fonts/Lato/Lato-normal-100.woff2) format("woff2");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  src: url(assets/fonts/Lato/Lato-normal-300.woff) format("woff"),
    url(assets/fonts/Lato/Lato-normal-300.ttf) format("truetype"),
    url(assets/fonts/Lato/Lato-normal-300.woff2) format("woff2");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/Lato/Lato.eot);
  src: local("Lato"),
    url(assets/fonts/Lato/Lato.eot?#iefix) format("embedded-opentype"),
    url(assets/fonts/Lato/Lato.woff) format("woff"),
    url(assets/fonts/Lato/Lato.ttf) format("truetype"),
    url(assets/fonts/Lato/Lato.woff2) format("woff2"),
    url(assets/fonts/Lato/Lato.svg#Lato) format("svg");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: url(assets/fonts/Lato/Lato-normal-700.woff) format("woff"),
    url(assets/fonts/Lato/Lato-normal-700.ttf) format("truetype"),
    url(assets/fonts/Lato/Lato-normal-700.woff2) format("woff2");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  src: url(assets/fonts/Lato/Lato-normal-900.woff) format("woff"),
    url(assets/fonts/Lato/Lato-normal-900.ttf) format("truetype"),
    url(assets/fonts/Lato/Lato-normal-900.woff2) format("woff2");
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 100;
  src: url(assets/fonts/Lato/Lato-italic-100.woff) format("woff"),
    url(assets/fonts/Lato/Lato-italic-100.ttf) format("truetype"),
    url(assets/fonts/Lato/Lato-italic-100.woff2) format("woff2");
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 300;
  src: url(assets/fonts/Lato/Lato-italic-300.woff) format("woff"),
    url(assets/fonts/Lato/Lato-italic-300.ttf) format("truetype"),
    url(assets/fonts/Lato/Lato-italic-300.woff2) format("woff2");
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 400;
  src: url(assets/fonts/Lato/Lato-italic-400.woff) format("woff"),
    url(assets/fonts/Lato/Lato-italic-400.ttf) format("truetype"),
    url(assets/fonts/Lato/Lato-italic-400.woff2) format("woff2");
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 700;
  src: url(assets/fonts/Lato/Lato-italic-700.woff) format("woff"),
    url(assets/fonts/Lato/Lato-italic-700.ttf) format("truetype"),
    url(assets/fonts/Lato/Lato-italic-700.woff2) format("woff2");
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 900;
  src: url(assets/fonts/Lato/Lato-italic-900.woff) format("woff"),
    url(assets/fonts/Lato/Lato-italic-900.ttf) format("truetype"),
    url(assets/fonts/Lato/Lato-italic-900.woff2) format("woff2");
}

@import "~@ng-select/ng-select/themes/default.theme.css";

html,
body {
  height: auto;
}

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  background: transparent;
  color: $primary-color;
}

.cursor-pointer {
  cursor: pointer !important;
}

.iti {
  width: 100%;
}

.calio-tel-input {
  font-size: 14px !important;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.calio-tel-input.is-invalid {
  border: 1px solid $red-color;
}

.error-message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: $red-color;
}

.is-invalid {
  .ng-select-container {
    border: 1px solid $red-color;
  }
}

.custom-control-input:focus~.custom-control-label::before {
  box-shadow: none !important;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: $primary-color !important;
  background-color: $primary-color !important;
}

.radio-item {
  display: inline-block;
  position: relative;
  margin: 10px 0 0;
}

.radio-item input[type="radio"] {
  display: none;
}

.radio-item label {
  color: $primary-color;
  font-weight: normal;
  position: relative;
  padding-left: 30px;
}

.radio-item label:before {
  content: " ";
  display: inline-block;
  position: absolute;
  top: 3px;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 11px;
  border: 2px solid $primary-color;
  background-color: transparent;
}

.radio-item input[type="radio"]:checked+label:after {
  content: " ";
  border-radius: 11px;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 8px;
  left: 5px;
  display: block;
  background: $primary-color;
}

.form-control {
  font-size: 14px;
}

.mat-body,
.mat-body-2,
.mat-typography,
.mat-expansion-panel-header,
.mat-mdc-checkbox,
.mat-mdc-radio-button,
.mat-stepper-vertical,
.mat-stepper-horizontal {
  font-family: "Lato", sans-serif !important;
  font-size: 14px !important;
  font: 400 14px/20px "Lato", sans-serif !important;
  letter-spacing: normal;
}

.mat-step-header.cdk-keyboard-focused,
.mat-step-header.cdk-program-focused,
.mat-step-header:hover {
  background-color: transparent !important;
}

@media (max-width: 999px) {

  .mat-horizontal-stepper-header .mat-step-icon,
  .mat-horizontal-stepper-header .mat-step-icon-not-touched {
    margin-right: 0 !important;
  }

  .mat-step-label {
    display: none !important;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  color: $primary-color;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  color: $primary-color;
}

// angular calendar starts

.cal-month-view .cal-cell-row:hover {
  background-color: transparent !important;
}

.cal-month-view .cal-cell-row .cal-cell:hover,
.cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: transparent !important;
}

.cal-month-view .cal-header .cal-cell {
  font-weight: 500;
}

.cal-month-view .cal-days {
  border-color: transparent !important;
}

.cal-month-view .cal-days .cal-cell-row {
  border-color: transparent !important;
}

.cal-month-view .cal-day-cell:not(:last-child) {
  border-color: transparent !important;
}

.cal-month-view .cal-cell {
  align-items: center !important;
  outline: none !important;
}

.cal-month-view .cal-day-number {
  font-size: 14px !important;
  font-weight: 500 !important;
  opacity: 1 !important;
  margin-top: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 2px;
}

.cancel-button {
  margin-right: 4px;
  height: 40px;
}

.cal-month-view .cal-day-cell.cal-today {
  background-color: transparent !important;
}

.cal-days {
  margin-top: 0px !important;
}

.cal-month-view .cal-day-cell {
  min-height: 0px !important;
  outline: -webkit-focus-ring-color auto 0px;
}

.cal-month-view {
  padding-top: 0px !important;
}

.cal-disabled {
  color: #e1e1e1 !important;
}

.cal-day-number {
  cursor: pointer !important;
}

.cal-selected {
  .cal-day-number {
    width: 40px;
    height: 26px;
    color: white !important;
    background-color: $primary-color;
    border-radius: 10px;
    position: relative;

    /*.custom-slots-badge {
      position: absolute;
      top: 0px;
    }*/
  }
}

.cal-selected-without-highlight {
  .cal-day-number {
    position: relative;
  }
}

.cal-month-view .cal-cell-top {
  min-height: 44px;
}

.cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
  color: $primary-color;
}

// angular calendar ends
.text-success {
  color: $green-color !important;
}

.btn-primary:focus,
.btn-primary.focus,
.btn:focus,
.btn.focus,
a:focus,
input[type="text"]:focus,
.ng-select:focus {
  box-shadow: none;
  outline: none !important;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.form-control:focus,
.ng-select-focused {
  border-color: #cccccc !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ng-select-focused {
  outline: 0px !important;
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  border-color: #ccc !important;
  box-shadow: none !important;
}

// alert css

.dropdown-toggle:after {
  content: none;
}

.notification-icon-detail {
  color: #999999;
  font-size: 11px;
}

.rounded {
  border-radius: 0.5rem !important;
}

.notification-title {
  font-size: 14px;
  font-weight: 600;
}

.notification-description {
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 26px;
  font-weight: 400;
}

.text-problem {
  color: #ef6565;
}

.text-announcement {
  color: #97cc2e;
}

.text-warning {
  color: #ffcb3d;
}

.text-information {
  color: #3a4a66;
}

.card-problem {
  border-left: 6px solid #ef6565;
}

.card-announcement {
  border-left: 6px solid #97cc2e;
}

.card-warning {
  border-left: 6px solid #ffcb3d;
}

.card-information {
  border-left: 6px solid #3a4a66;
}

.icon-block {
  width: 12%;
}

.content-block {
  width: 88%;
}

@media (max-width: 575.98px) {
  .border-xs-left-none {
    border-left: none !important;
  }

  .border-xs-right-none {
    border-right: none !important;
  }

  .border-xs-top-none {
    border-top: none !important;
  }

  .border-xs-bottom-none {
    border-bottom: none !important;
  }
}

@media (max-width: 767.98px) {
  .border-sm-left-none {
    border-left: none !important;
  }

  .border-sm-right-none {
    border-right: none !important;
  }

  .border-sm-top-none {
    border-top: none !important;
  }

  .border-sm-bottom-none {
    border-bottom: none !important;
  }
}

@media (max-width: 991.98px) {
  .border-md-left-none {
    border-left: none !important;
  }

  .border-md-right-none {
    border-right: none !important;
  }

  .border-md-top-none {
    border-top: none !important;
  }

  .border-md-bottom-none {
    border-bottom: none !important;
  }
}

@media (max-width: 1199.98px) {
  .border-lg-left-none {
    border-left: none !important;
  }

  .border-lg-right-none {
    border-right: none !important;
  }

  .border-lg-top-none {
    border-top: none !important;
  }

  .border-lg-bottom-none {
    border-bottom: none !important;
  }
}

.border-xl-left-none {
  border-left: none !important;
}

.border-xl-right-none {
  border-right: none !important;
}

.border-xl-top-none {
  border-top: none !important;
}

.border-xl-bottom-none {
  border-bottom: none !important;
}

a {
  color: #007bff;
  text-decoration: none;
}

.spin-animation {
  animation: spin 1s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}
